<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('SEASON_CONTENT')}}</h1>
    <div class="content-container">
      <div class="highlights-container">
        <span class="highlights-header">{{ $t("SEASON_VALUES.HIGHLIGHT_INDEXES") }}</span>
        <input v-for="(val, i) in seasonContentObj.highlight_indexes || []" v-bind:key="i" v-model.number="seasonContentObj.highlight_indexes[i]" />
        <button class="success" @click="addHighlightIndex"><i class="fas fa-plus"></i></button>
        <button class="error" @click="removeHighlightIndex" :disabled="seasonContentObj.highlight_indexes.length === 0"><i class="fas fa-minus"></i></button>
      </div>
      <div class="table">
        <div class="header-row">
          <div class="cell header-cell">{{ $t('SEASON_VALUES.MILESTONE') }}</div>
          <div class="cell header-cell">{{ $t('SEASON_VALUES.FREE_REWARD') }}</div>
          <div class="cell header-cell">{{ $t('SEASON_VALUES.PREMIUM_REWARD') }}</div>
        </div>
        <div class="table-row" v-for="(row, index) in seasonContentObj.milestones" v-bind:key="index" :class="index % 2 === 1 ? 'alternate' : ''" >
          <div class="cell">
            <input v-model.number="row.milestone" />
          </div>
          <div class="cell subcell">
            <p>{{ $t('SEASON_VALUES.TYPE') }}</p>
            <select v-model.number="seasonContentObj.milestones[index].sp_rewards.type" @change="() => updateRewardType(index, false)">
              <option v-for="type in seasonConstants.SEASON_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t('SEASON_VALUES.' + type.text) }}</option>
            </select>
          </div>
          <div class="cell reward-cell">
            <template v-if="[0,1,3,4,10,11].includes(seasonContentObj.milestones[index].sp_rewards.type)">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.AMOUNT') }}</p>
                <input v-model.number="seasonContentObj.milestones[index].sp_rewards.amount" />
              </div>
            </template>
            <template v-if="seasonContentObj.milestones[index].sp_rewards.type === 2">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.CHEST') }}</p>
                <select v-model="seasonContentObj.milestones[index].sp_rewards.id">
                  <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
                </select>
              </div>
            </template>
            <template v-if="[3,4,10,11].includes(seasonContentObj.milestones[index].sp_rewards.type)">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.RARITY') }}</p>
                <select v-model.number="seasonContentObj.milestones[index].sp_rewards.rarity">
                  <option v-for="option in seasonConstants.RARITIES" :value="option.value" v-bind:key="option.value">{{ option.text }}</option>
                </select>
              </div>
            </template>
            <template v-if="seasonContentObj.milestones[index].sp_rewards.type === 6">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.EMOJI_INDEX') }}</p>
                <input v-model.number="seasonContentObj.milestones[index].sp_rewards.emoji_index" />
              </div>
            </template>
            <template v-if="seasonContentObj.milestones[index].sp_rewards.type === 14">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.AVATAR_INDEX') }}</p>
                <input v-model.number="seasonContentObj.milestones[index].sp_rewards.avatar_index" />
              </div>
            </template>
          </div>
          <div class="cell subcell">
            <p>{{ $t('SEASON_VALUES.TYPE') }}</p>
            <select v-model.number="seasonContentObj.milestones[index].spp_rewards.type" @change="() => updateRewardType(index, true)">
              <option v-for="type in seasonConstants.SEASON_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t('SEASON_VALUES.' + type.text) }}</option>
            </select>
          </div>
          <div class="cell reward-cell">
            <template v-if="[0,1,3,4,10,11].includes(seasonContentObj.milestones[index].spp_rewards.type)">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.AMOUNT') }}</p>
                <input v-model.number="seasonContentObj.milestones[index].spp_rewards.amount" />
              </div>
            </template>
            <template v-if="seasonContentObj.milestones[index].spp_rewards.type === 2">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.CHEST') }}</p>
                <select v-model="seasonContentObj.milestones[index].spp_rewards.id">
                  <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
                </select>
              </div>
            </template>
            <template v-if="[3,4,10,11].includes(seasonContentObj.milestones[index].spp_rewards.type)">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.RARITY') }}</p>
                <select v-model.number="seasonContentObj.milestones[index].spp_rewards.rarity">
                  <option v-for="option in seasonConstants.RARITIES" :value="option.value" v-bind:key="option.value">{{ option.text }}</option>
                </select>
              </div>
            </template>
            <template v-if="seasonContentObj.milestones[index].spp_rewards.type === 6">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.EMOJI_INDEX') }}</p>
                <input v-model.number="seasonContentObj.milestones[index].spp_rewards.emoji_index" />
              </div>
            </template>
            <template v-if="seasonContentObj.milestones[index].spp_rewards.type === 14">
              <div class="subcell">
                <p>{{ $t('SEASON_VALUES.AVATAR_INDEX') }}</p>
                <input v-model.number="seasonContentObj.milestones[index].spp_rewards.avatar_index" />
              </div>
            </template>
          </div>
          <div class="cell">
            <button class="error" @click="() => removeReward(index)">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>
      <button class="success" @click="addReward">{{ $t('SEASON_VALUES.ADD_REWARD') }}</button>
      <div class="grind-row">
        <div class="form-container">
          <span>{{ $t('SEASON_VALUES.GRIND_STEP') }}</span>
          <input v-model.number="seasonContentObj.grind.step" />
        </div>
        <div class="form-container">
          <span>{{ $t('SEASON_VALUES.GRIND_AMOUNT') }}</span>
          <input v-model.number="seasonContentObj.grind.amount" />
        </div>
      </div>
    </div>
    <div class="buttons-section">
      <button class="success" @click="openConfirm">Save</button>
      <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
      <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
      <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>

  </div>
</template>

<script>
import { ref, computed, watch, onBeforeMount, onUpdated, onBeforeUnmount } from "vue";
import { useStore } from "vuex";

import seasonConstants from "../../constants/seasonConstants";
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
import changeDetector from '@/util/changeDetector'

export default {
  name: "SeasonContent",
  components: {
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const usedPages = ["seasonContent"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupTexts = ["This page is edited by another user. Please refresh before making changes.", "Your changes: "];
    const popupText = ref(popupTexts[0])
    const popupType = ref("refresh")
    const changes = ref([]);
    onBeforeMount(() => {
      usedPages.forEach(page => {
          dispatchMap[page].forEach((dispatchStr) => {
            store.dispatch(dispatchStr);
          })
      });
      }
    );
    const seasonContentObj = ref({
      milestones: [],
      grind: {
        step: 250,
        amount: 250
      }
    });

    const chests = computed(() => store.getters['chests/getChests']);
    const units = computed(() => store.getters['units/getUnits']);
    const heroes = computed(() => store.getters['heroes/getHeroes']);

    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(() => store.getters['seasons/getSeasonContent'], value => {
      seasonContentObj.value = JSON.parse(JSON.stringify(value))
      window.firstSeasonContent = JSON.parse(JSON.stringify(value))
      // seasonContentObj.value.milestones = JSON.parse(JSON.stringify(value.milestones))
      // seasonContentObj.value.grind = JSON.parse(JSON.stringify(value.grind))

    }, { immediate: true })


    const submit = () => {
      store.dispatch('seasons/editSeasonContent', seasonContentObj.value).then(() => {
        socket.emit("update", {
          socketId: socket.id,
          itemId: "Season Content",
          page: "Season Content",
          data: seasonContentObj.value
        });
        popupOpen.value = false;
        changes.value = [];
      })
    }

    socket.on('updated', (data) => {
      if (data.socketId != socket.id && data.page === "Season Content" && data.data){
        popupOpen.value = true;
        popupType.value = "refresh";
        popupText.value = popupTexts[0];
        changes.value = changeDetector.detect(seasonContentObj.value, data.data, "seasonContent")
      }
    })
    const addReward = () => {
      seasonContentObj.value.milestones.push({
        milestone: 0,
        sp_rewards: {
          type: 0,
          amount: 0
        },
        spp_rewards: {
          type: 1,
          amount: 0
        }
      })
    }

    const removeReward = index => {
      seasonContentObj.value.milestones.splice(index, 1)
    }


    const updateRewardType = (index, is_premium) => {
      const field = is_premium ? 'spp_rewards': 'sp_rewards';
      const type = seasonContentObj.value.milestones[index][field].type;
      seasonContentObj.value.milestones[index][field] = {
        type
      };
      if(type === 0 || type === 1)
        seasonContentObj.value.milestones[index][field].amount = 0;
      if(type === 2) {
        seasonContentObj.value.milestones[index][field].id = "";
      }
      if(type === 10 || type === 11) {
        seasonContentObj.value.milestones[index][field].amount = 0;
        seasonContentObj.value.milestones[index][field].rarity = 0;
      }
      if(type === 6) {
        seasonContentObj.value.milestones[index][field].emoji_index = 0;
      }
    }

    const addHighlightIndex = () => {
      seasonContentObj.value.highlight_indexes.push(0)
    }

    const removeHighlightIndex = () => {
      seasonContentObj.value.highlight_indexes.splice(seasonContentObj.value.highlight_indexes.length - 1, 1)

    }

    const exportTo = environment => {
      store.dispatch('seasons/exportSeasonContent', { seasonContent: seasonContentObj.value, environment })
    }

    const openConfirm = () => {
      popupType.value = "confirm";
      popupOpen.value = true;
      popupText.value = popupTexts[1];
      changes.value = changeDetector.detect(window.firstSeasonContent, seasonContentObj.value, "seasonContent")
    }

    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      seasonContentObj,
      submit,
      env,
      seasonConstants,
      chests,
      units,
      heroes,
      updateRewardType,
      addReward,
      removeReward,
      exportTo,
      addHighlightIndex,
      removeHighlightIndex,
      popupOpen,
      popupText,
      popupType,
      changes,
      openConfirm
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.content-row.alternate {
  background-color: #f8f9fa;
}

.row-title {
  width: 80%;
  text-align: start;
}

.col {
  border: 1px solid black;
}

.col-title {
  padding: 20px 0;
  font-weight: 900;
  font-size: 1.5rem;
  background-color: #e0e0e0;
}

.category-title {
  padding: 20px 0 ;
  font-weight: bold;
  font-size: 1.25rem;
  background-color: #f0f0f0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.add-button {
  margin: 25px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

.header-container {
  display: grid;
  grid-template-columns: 5fr 5fr 1fr;
  grid-column-gap: 10px;
}

.form-container {
  display: flex;
  margin: 25px;
  padding: 10px;
  border: 1px solid black;

}

.sub-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.form-container p {
  width: 60%;
  text-align: start;
  font-weight: bold;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}

.form-container span {
  margin-right: 20px;
}

.table {
  width: 100%;
}

.reward-cell, .cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.header-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  background-color: lightgrey;
}

.header-cell {
  font-size: 1.3rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 2fr 1fr;
  padding: 15px 0;
  border-bottom: 1px solid black;
}

.table-row.alternate {
  background-color: lightgrey;
}

.type-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.subcell-grow {
  flex-grow: 1;
}

.grind-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.subcell {
  padding: 0 5px;
  height: 100%;
  flex-direction: column;
}

.subcell p {
  font-weight: bold;
  text-decoration: underline;
}

.highlights-container {
  padding: 10px 0 25px;
}

.highlights-container * {
  margin: 0 5px;
}

.highlights-container input {
  width: 2rem;
}

.highlights-header {
  font-weight: 900;
}

</style>